.photoviewer-loader {
  position: absolute;
  top: 30px;
  left: 0;
  right: 0;
  bottom: 40px;
  z-index: 2;
  text-align: center;
  color: #333;

  &::before {
    content: '';
    position: relative;
    display: inline-block;
    width: 36px;
    height: 36px;
    box-sizing: border-box;
    border-width: 5px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .5) rgba(0, 0, 0, .5) rgba(0, 0, 0, .5) rgba(255, 255, 255, .5);
    border-radius: 100%;
    vertical-align: middle;
    animation: photoviewerLoading 1s infinite linear;
  }

  &::after {
    content: '';
    display: inline-block;
    width: 0;
    height: 100%;
    vertical-align: middle;
    overflow: hidden;
  }
}

@keyframes photoviewerLoading {
  0% {
    transform: rotateZ(0deg) translate3d(0, 0, 0);
  }

  100% {
    transform: rotateZ(360deg) translate3d(0, 0, 0);
  }
}
