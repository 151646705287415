.photoviewer-modal:fullscreen {
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  background-color: black;
  border-width: 0;
  border-radius: 0;

  .photoviewer-header,
  .photoviewer-footer,
  .photoviewer-resizable-handle {
    display: none;
  }

  .photoviewer-stage {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-width: 0;
    background-color: transparent;
  }
}
