@use 'sass:map';
@use 'sass:meta';
@use 'sass:list';

@use 'functions';
@use 'variables';
@use 'position';
@use 'display';
@use 'flexbox';
@use 'sizing';
@use 'spacing';
@use 'border';
@use 'rounded';
@use 'text';
@use 'alignment';
@use 'overflow';
@use 'interaction';
@use 'object';

$utilities: functions.map-collect(
  variables.$utilities,
  position.$utilities,
  display.$utilities,
  flexbox.$utilities,
  sizing.$utilities,
  spacing.$utilities,
  border.$utilities,
  rounded.$utilities,
  text.$utilities,
  alignment.$utilities,
  overflow.$utilities,
  interaction.$utilities,
  object.$utilities,
);

@each $key, $utility in $utilities {
  $values: map.get($utility, values);

  // If the values are a list or string, convert it into a map
  @if meta.type-of($values) == 'string' or meta.type-of(list.nth($values, 1)) != 'list' {
    $values: list.zip($values, $values);
  }

  $properties: map.get($utility, property);
  $property-class-prefix: map.get($utility, class);

  // Utility class maybe empty, (e.g. with position class)
  $property-class-prefix-hyphen: if(
    $property-class-prefix == '',
    $property-class-prefix,
    $property-class-prefix + '-'
  );

  @each $class-modifier, $value in $values {
    .#{$property-class-prefix-hyphen + $class-modifier} {
      @each $property in $properties {
        #{$property}: $value !important;
      }
    }
  }
}
