@use 'sass:color';
@use 'sass:map';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $background: map.get($theme, background);
  $foreground: map.get($theme, foreground);
  $primary: map.get($theme, primary);
  $accent: map.get($theme, accent);
  $is-dark-theme: map.get($theme, is-dark);
  $root-name: if($is-dark-theme, '.theme-dark', ':root');

  :root {
    --body-font-family: 'Roboto', 'Helvetica Neue Light', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif;
    --code-font-family: 'Roboto Mono', monospace;
    --gutter: 16px;
    --sidenav-width: 241px;
    --sidenav-collapsed-width: 51px;
  }

  @at-root {
    #{$root-name} {
      --divider-color: #{mat.get-color-from-palette($foreground, divider)};
      --link-color: #{mat.get-color-from-palette(mat.$blue-palette, if($is-dark-theme, 400, 600))};
      --link-hover-color: #{color.adjust(mat.get-color-from-palette(mat.$blue-palette, if($is-dark-theme, 400, 600)), $lightness: -10%)};
      --code-border-color: #{rgba(mat.get-color-from-palette($foreground, secondary-text), .08)};
      --code-background-color: #{rgba(mat.get-color-from-palette($foreground, secondary-text), .04)};
      --sidemenu-heading-hover-background-color: #{mat.get-color-from-palette($background, hover)};
      --sidemenu-active-heading-text-color: #{if($is-dark-theme, mat.get-color-from-palette(mat.$blue-palette, 200), mat.get-color-from-palette($accent))};
      --sidemenu-active-heading-background-color: #{if($is-dark-theme, rgba(mat.get-color-from-palette($primary), .15), transparent)};
      --sidemenu-active-heading-hover-background-color: #{mat.get-color-from-palette($background, hover)};
      --sidemenu-expanded-background-color: #{mat.get-color-from-palette($background, hover)};
      --topmenu-background-color: #{mat.get-color-from-palette($background, app-bar)};
      --topmenu-item-active-background-color: #{mat.get-color-from-palette($background, focused-button)};
      --topmenu-dropdown-item-active-text-color: #{mat.get-color-from-palette($accent)};
      --toast-info-background-color: #{mat.get-color-from-palette(mat.$blue-palette, if($is-dark-theme, 900, 500))};
      --toast-success-background-color: #{mat.get-color-from-palette(mat.$green-palette, if($is-dark-theme, 900, 500))};
      --toast-warning-background-color: #{mat.get-color-from-palette(mat.$orange-palette, if($is-dark-theme, 900, 500))};
      --toast-error-background-color: #{mat.get-color-from-palette(mat.$red-palette, if($is-dark-theme, 900, 500))};
    }
  }
}
