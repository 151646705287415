.photoviewer-modal {
  position: absolute;
  z-index: 1090;
  width: 320px;
  height: 320px;
  color: #333;
  background-color: rgba(255, 255, 255, .9);
  border: 1px solid rgba(0, 0, 0, .6);
  border-radius: 6px;
  box-shadow: 0 2px 10px 2px rgba(0, 0, 0, .3);
  outline: none;

  &:focus-visible {
    outline: thick solid rgba(0, 0, 0, .2);
  }
}

.photoviewer-inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.photoviewer-maximized {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: auto;
  height: auto;

  &.photoviewer-modal {
    border-width: 0;
    border-radius: 0;
  }

  .photoviewer-header {
    border-radius: 0;
  }

  .photoviewer-resizable-handle {
    display: none;
  }
}

.photoviewer-button {
  display: inline-block;
  min-width: 40px;
  height: 40px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 1;
  background: none;
  border-width: 0;
  color: inherit;
  cursor: pointer;
  outline: none;

  &:hover {
    color: #111;
  }

  &:focus {
    background-color: rgba(0, 0, 0, .1);
  }

  svg {
    display: inline-block;
    font-size: inherit;
    width: 1em;
    height: 1em;
    overflow: visible;
    vertical-align: -.125em;
  }
}

.photoviewer-header {
  position: relative;
  z-index: 2;
  height: 30px;
  border-radius: 5px 5px 0 0; // 6px - 1px
  overflow: hidden;

  .photoviewer-toolbar {
    float: right;

    [dir='rtl'] & {
      float: left;
    }
  }

  .photoviewer-button {
    height: 30px;

    &:hover {
      background-color: rgba(0, 0, 0, .1);
    }
  }

  .photoviewer-button-close {
    &:hover {
      color: #fff;
      background-color: #ff4545;
    }
  }
}

.photoviewer-title {
  padding: 8px 10px;
  font-size: 14px;
  line-height: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  user-select: none;
  overflow: hidden;
}

.photoviewer-stage {
  position: absolute;
  top: 30px;
  right: 0;
  bottom: 40px;
  left: 0;
  z-index: 1;
  border-top: 1px solid rgba(0, 0, 0, .6);
  border-bottom: 1px solid rgba(0, 0, 0, .6);
  background-color: #fff;
  overflow: hidden;
}

.photoviewer-image {
  position: absolute;
  display: inline-block;
  min-width: auto;
  max-width: none;
}

.photoviewer-footer {
  position: absolute;
  bottom: 0;
  z-index: 2;
  width: 100%;
  height: 40px;
  text-align: center;
}

.photoviewer-align-center {
  text-align: center;

  &::before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    overflow: hidden;
  }

  .photoviewer-image {
    position: static;
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle;
  }
}

.photoviewer-image-error {
  display: none;
}

.photoviewer-error-msg {
  vertical-align: middle;
}

[dir='rtl'] {
  .photoviewer-button-prev,
  .photoviewer-button-next {
    transform: rotate(180deg);
  }
}
