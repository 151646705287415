@use 'style/reboot';
@use 'style/colors';
@use 'style/scrollbar';

.scrollbar-thin {
  @include scrollbar.thin();
}

.scrollbar-none {
  @include scrollbar.none();
}

@media (width <= 720px) {
  .hide-small {
    display: none !important;
  }

  .show-small {
    display: block !important;
  }
}
