.photoviewer-resizable-handle {
  position: absolute;
  z-index: 10;
}

.photoviewer-resizable-handle-e {
  top: 0;
  right: -5px;
  bottom: 0;
  left: auto;
  width: 10px;
  cursor: e-resize;
}

.photoviewer-resizable-handle-s {
  top: auto;
  right: 0;
  bottom: -5px;
  left: 0;
  height: 10px;
  cursor: s-resize;
}

.photoviewer-resizable-handle-w {
  top: 0;
  right: auto;
  bottom: 0;
  left: -5px;
  width: 10px;
  cursor: w-resize;
}

.photoviewer-resizable-handle-n {
  top: -5px;
  right: 0;
  bottom: auto;
  left: 0;
  height: 10px;
  cursor: n-resize;
}

.photoviewer-resizable-handle-se {
  top: auto;
  right: -5px;
  bottom: -5px;
  left: auto;
  width: 10px;
  height: 10px;
  cursor: se-resize;
}

.photoviewer-resizable-handle-sw {
  top: auto;
  right: auto;
  bottom: -5px;
  left: -5px;
  width: 10px;
  height: 10px;
  cursor: sw-resize;
}

.photoviewer-resizable-handle-nw {
  top: -5px;
  right: auto;
  bottom: auto;
  left: -5px;
  width: 10px;
  height: 10px;
  cursor: nw-resize;
}

.photoviewer-resizable-handle-ne {
  top: -5px;
  right: -5px;
  bottom: auto;
  left: auto;
  width: 10px;
  height: 10px;
  cursor: ne-resize;
}
