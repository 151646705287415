.is-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.is-grabbing {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
