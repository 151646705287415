@use '@angular/material' as mat;
@use 'app-theme';

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

//
// Light theme
//
$primary: mat.define-palette(mat.$indigo-palette);
$accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$theme: mat.define-light-theme(
  (
    color: (
      primary: $primary,
      accent: $accent
    ),
    typography: mat.define-typography-config(),
    density: 0
  )
);

@include app-theme.theme($theme);

//
// Dark theme
//
.theme-dark {
  color-scheme: dark;

  $primary: mat.define-palette(mat.$blue-palette);
  $accent: mat.define-palette(mat.$amber-palette, A200, A100, A400);
  $theme: mat.define-dark-theme(
    (
      color: (
        primary: $primary,
        accent: $accent
      ),
      typography: null,
      density: null
    )
  );

  @include app-theme.theme($theme);
}
